import React from 'react';
import { SEO, Layout } from "components"
import { StaticQuery, graphql } from "gatsby"
import TeamMember from './components/TeamMember';
import styles from './styles.module.scss';

interface TeamMemberNode {
  frontmatter: {
    name: string;
    photo: string;
    philosophy: string;
    about: string;
    favourites: string;
  }
}

interface TeamMember {
  name: string;
  photo: string;
  philosophy: string;
  about: string;
  favourites: string;
}

const Rates = () => (
  <Layout>
    <SEO />
    <div className={styles.ratesPage}>
      <div className={styles.ratesTable}>
      <StaticQuery
        query={graphql`
        query TeamQuery {
          allMarkdownRemark (
            filter: { fileAbsolutePath: {regex : "\/staff/"} }
          ) {
            nodes {
              frontmatter {
                name
                photo
                philosophy
                about
                favourites
              }
            }
          }
        }`}
        render={data => {
          const team = data.allMarkdownRemark.nodes.map((tm: TeamMemberNode) => (
            {
              name: tm.frontmatter.name,
              photo: tm.frontmatter.photo,
              philosophy: tm.frontmatter.philosophy,
              about: tm.frontmatter.about,
              favourites: tm.frontmatter.favourites
            }
          )).reverse();
          return (
            <div id="team" className={styles.rateRow}>
              {!!team.length && team.map((tm: TeamMember) => (
                <TeamMember key={tm.name} { ...tm } />
              ))}
          </div>
          )
        }}
      />
      </div>
    </div>
  </Layout>
)

export default Rates;
