import React from 'react';
import Markdown from 'react-markdown';
import styles from './styles.module.scss';
interface TeamMember {
  name: string;
  photo: string;
  philosophy: string;
  about: string;
  favourites: string;
}

const TeamMember = ( { name, photo, philosophy, about, favourites }: TeamMember ) => {
  return (
    <div className={styles.teamMember}>
      <div className={styles.photo}>
        <img src={photo} alt={name} />
      </div>
      <div className={styles.content}>
        <h3>{name}</h3>
        <strong>About</strong>
        <Markdown children={about } />
        <strong>Philosophy</strong>
        <Markdown children={philosophy} />
        <strong>Favourites</strong>
        <Markdown children={favourites} />
      </div>
    </div>
  )
}

export default TeamMember;
